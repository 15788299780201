document.addEventListener('DOMContentLoaded', () => {
  if (!document.querySelectorAll('.carousel')?.length) return

  // Show second slide initially on larger screens, so it does not look weird
  let activeSlide = window.innerWidth <= 768 ? 1 : 2
  document.querySelectorAll('.carousel').forEach(item => item.classList.add(`active-${activeSlide}`))

  document.querySelector('.carousel button.prev').addEventListener('click', event => {
    const carousel = event.target.closest('.carousel')
    if (activeSlide > 1) activeSlide = activeSlide - 1
    carousel.className = `carousel ${'active-' + activeSlide}`
  })

  document.querySelector('.carousel button.next').addEventListener('click', event => {
    const carousel = event.target.closest('.carousel')
    if (activeSlide) activeSlide = activeSlide + 1
    carousel.className = `carousel ${'active-' + activeSlide}`
  })
})
