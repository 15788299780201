function moveSlider(button, index) {
    const slider = button.closest('.tablet')?.querySelector('.tablet-slider')
    if (!slider) return
    const slidesNum = slider.querySelectorAll('.tablet-slide')?.length
    const newIndex = parseInt(slider.dataset.slide) + index

    let result
    if (newIndex < 0) {
        result = slidesNum - 1
    } else if (newIndex >= slidesNum) {
        result = 0
    } else {
        result = newIndex
    }

    slider.dataset.slide = result
    slider.className = `tablet-slider red tablet-slider--${result}`
}

document.addEventListener('DOMContentLoaded', () => {
    if (!document.querySelectorAll('.tablet')?.length) return

    // Date
    const dateEls = document.querySelectorAll('.tablet-panel-date')
    if (dateEls?.length) {
        const date = new Date()
        const dateStr = date.toDateString().split(' ').slice(0, -1).join(' ')
        const timeStr = [date.getHours(), date.getMinutes()].map(num => num.toString().padStart(2, '0')).join(':')
        dateEls.forEach(dateEl => {
            dateEl.innerHTML = `<span>${timeStr}</span><span>${dateStr}</span>`
        })
    }

    // Button
    const buttonsPrev = document.querySelectorAll('.tablet-button-prev')
    if (buttonsPrev.length > 0) {
        buttonsPrev.forEach(buttonPrev => {
            buttonPrev.addEventListener('click', event => moveSlider(event.target, -1))
        })
    }
    const buttonsNext = document.querySelectorAll('.tablet-button-next')
    if (buttonsNext.length > 0) {
        buttonsNext.forEach(buttonNext => {
            buttonNext.addEventListener('click', event => moveSlider(event.target, 1))
        })
    }
})
