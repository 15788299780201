document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('button.toggle-card').forEach(item => {
    item.addEventListener('click', event => {
      const parentCard = event.target.closest('.card')
      if (parentCard?.classList.contains('active')) {
        parentCard.classList.remove('active')
      } else if (!parentCard?.classList.contains('active')) {
        parentCard.classList.add('active')
      }
    })
  })
})
