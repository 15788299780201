const searchParamsSaverPrefix = 'ot-search-param-saver'
const supportedSearchParams = ['referrer', 'userGroup']

/**
 * Get search params from the URL.
 * Then search through all links with data-append-params attribute.
 * If data attribute has the search params name, add it to the URL.
 * "data-append-params" is a comma separated list of params to add to the URL.
 */
document.addEventListener('DOMContentLoaded', event => {
  if (!window.sessionStorage) return
  const urlParams = new URLSearchParams(document.location.search)

  // Save every supported param
  supportedSearchParams.forEach(param => {
    const paramValue = urlParams.get(param)
    if (paramValue) window.sessionStorage.setItem(`${searchParamsSaverPrefix}-${param}`, paramValue)
  })

  // Search through all links with data-append-params attribute
  document.querySelectorAll('a[data-append-params]').forEach(aElement => {
    const linkHref = new URL(aElement.getAttribute('href'))
    const urlParamsLink = new URLSearchParams(linkHref.search)
    const separatedParams = aElement.dataset.appendParams.split(',')

    // Append every supported stored param
    supportedSearchParams.forEach(param => {
      const storedParam = window.sessionStorage.getItem(`${searchParamsSaverPrefix}-${param}`)
      if (storedParam && separatedParams.includes(param)) urlParamsLink.append(param, storedParam)
    })

    linkHref.search = urlParamsLink
    aElement.setAttribute('href', linkHref.href)
  })
})
