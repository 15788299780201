/* eslint-disable max-nested-callbacks */
document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.accordion-button').forEach(item => {
    item.addEventListener('click', event => {
      const parentAccordionItem = event.target.closest('.accordion-item')
      if (parentAccordionItem?.classList.contains('active')) {
        parentAccordionItem.classList.remove('active')
        parentAccordionItem.querySelectorAll('.accordion-content').forEach(accC => {
          accC.style.maxHeight = null
        })
      } else if (!parentAccordionItem?.classList.contains('active')) {
        const parentAccordion = event.target.closest('.accordion')
        parentAccordion.querySelectorAll('.accordion-item').forEach(accItem => {
          accItem.classList.remove('active')
          accItem.querySelectorAll('.accordion-content').forEach(accC => {
            accC.style.maxHeight = null
          })
        })
        setTimeout(() => {
          window.scrollTo({
            top: parentAccordionItem.getBoundingClientRect().top + window.scrollY - 100,
            behavior: 'smooth'
          })
          parentAccordionItem.classList.add('active')
          parentAccordionItem.querySelectorAll('.accordion-content').forEach(accC => {
            accC.style.maxHeight = accC.scrollHeight + 50 + 'px'
          })
        }, 100)
      }
    })
  })

  if (document.location.hash === '#cookies') {
    document.querySelector('.accordion-button.whatisacookie').click()
  }
})
